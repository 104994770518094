import { graphql } from 'gatsby';
import React from 'react';
import ImageOpt from '../ImageOpt.js';

const Banner = ({ module }) => {
  return (
    <>
      <section className="banner h-screen relative">
        <ImageOpt
          imgData={module?.backgroundImage.mediaItemUrl}
          imgAlt="banner"
          imgLoad="eager"
          width={1440}
          height={856}
          imgClass="banner-img h-full w-full absolute top-0 left-0 z-[1] object-cover"
          imgFetchPriority="high"
        />
        <div className="banner-btm-text relative z-[5] w-full h-full">
          <div className="banner-info text-center lg:pb-120 pb-50  fade-ani absolute bottom-0 w-full">
            <div className="flex justify-center lg:space-x-10 space-x-5">
              <h6 className="font-body tracking-018em text-14 text-white relative uppercase">
                Blog
              </h6>
            </div>
            <div className="banner-title mt-30">
              <h1 className="text-white tracking-004em up">{module.title}</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

export const BannerBlogFragment = graphql`
  fragment BannerBlogFragment on WpPost_Acfblogcontent_BlogContent_Banner {
    # content
    id
    extraClass
    title
    fieldGroupName
    backgroundImage {
      altText
      mediaItemUrl
      uri
      title
    }
  }
`;
