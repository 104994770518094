import { graphql, useStaticQuery } from 'gatsby';

export const BlogListingData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpBlogsPost {
      posts: allWpPost(limit: 3) {
        edges {
          node {
            title
            uri
            excerpt
            featuredImage {
              node {
                altText
                mediaItemUrl
                title
                uri
              }
            }
          }
        }
      }
    }
  `);
  return data;
};
