import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';
import { BlogListingData } from './BlogListingData';

const ExploreCard = () => {
  const postData = BlogListingData();
  const post = postData.posts.edges;
  return (
    <>
      <section className="blog lg:py-60 py-30">
        <div className="container-fluid">
          <div className="blog-top fade-ani">
            <div className="lg:w-8/12 w-full">
              <span className="text-tan text-14 tracking-004em font-400 uppercase font-body">
                Related blogs
              </span>
              <div className="title-black mt-10">
                <h2>Discover other stories like this one</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-grid">
          <div className="container-fluid">
            <div className="flex flex-wrap lg:mx-minus-15">
              {post.map((item, i) => {
                return (
                  <div className="lg:w-4/12 w-full lg:mt-50 mt-20 lg:px-15">
                    <div className="blog-bx">
                      {item.node.featuredImage && (
                        <Link className="cursor-pointer" to={item.node.uri}>
                          {' '}
                          <ImageOpt
                            imgData={item.node.featuredImage.node.mediaItemUrl}
                            imgAlt="Location"
                            imgLoad="lazy"
                            width={410}
                            height={318}
                            imgClass="object-cover h-full w-full"
                          />
                        </Link>
                      )}
                      <div className="blog-content py-30 lg:pr-50">
                        <Link className="cursor-pointer" to={item.node.uri}>
                          <h4 className="text-dark text-24 tracking-003em font-400">
                            {item.node.title}
                          </h4>
                        </Link>
                        {item.node.excerpt && parse(item.node.excerpt)}
                        <div className="link-btn mt-15">
                          <span></span>
                          <Link className="cursor-pointer" to={item.node.uri}>
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreCard;
export const ExploreCardFragment = graphql`
  fragment ExploreCardFragment on WpPage_Acfpagecontent_PageContent_ExploreCard {
    # content
    id
    extraClass
  }
`;
