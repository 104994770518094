import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';

const SocialShare = ({ module, i, postData }) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    setUrl(window.location.href);
  });
  return (
    <>
      <section className="blog-share lg:py-60 py-30">
        <div className="container-fluid pl-0">
          <div className="title-brown flex items-center justify-end title-line title-right">
            <h3>Share this story</h3>
            <ul className="flex flex-wrap space-x-4 pl-20 bg-tanlight relative z-9">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://t.me/share/url?url=${encodeURIComponent(url)}`}
              >
                <img
                  src="../../images/telegram.svg"
                  loading="lazy"
                  alt="Telegram"
                />
              </a>
              <li>
                <a href="#">
                  <img
                    src="../../images/instagram-icon.svg"
                    loading="lazy"
                    alt="Instagram"
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`http://www.facebook.com/sharer.php?u=${encodeURIComponent(
                    url
                  )}`}
                >
                  <img
                    src="../../images/facebook-icon.svg"
                    loading="lazy"
                    alt="Facebook"
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    url
                  )}`}
                >
                  <img
                    src="../../images/twitter-icon.svg"
                    loading="lazy"
                    alt="Twitter"
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.linkedin.com/sharing/share-offsite=${encodeURIComponent(
                    url
                  )}`}
                >
                  <img
                    src="../../images/linkedin.svg"
                    loading="lazy"
                    alt="LinkedIn"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default SocialShare;

export const SocialShareBlogFragment = graphql`
  fragment SocialShareBlogFragment on WpPost_Acfblogcontent_BlogContent_SocialShare {
    # content
    id
    fieldGroupName
    extraClass
  }
`;
