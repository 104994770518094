import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import BlogModules from '../components/ModuleBlog/BlogModules';
import Seo from '../components/Seo/Seo';

const PageTemplate = (props) => {
  const post = props.data.post;
  useEffect(() => {
    document.body.classList.remove('mega-menu-open');
    document.body.classList.remove('booking-open');
    document.body.classList.remove('form-enquiry-open');
    document.body.classList.remove('menu-open');
  });
  return (
    <Layout>
      <Seo seo={post.seo}></Seo>
      <BlogModules modules={post.AcfBlogContent} postData={post} />
    </Layout>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query BlogById($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      title
      featuredImage {
        node {
          altText
          mediaItemUrl
        }
      }
      seo {
        canonical
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...BlogModulesFragment
    }
  }
`;
