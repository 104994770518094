import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const BlogContent = ({ module }) => {
  return (
    <>
      <section className="blog-single lg:pt-60 pt-30">
        <div className="container-fluid">
          <div className="lg:px-100 px-0">
            <div className="lg:px-60 px-0 blog-single-title">
              <span className="text-tan text-14 tracking-004em font-400 uppercase font-body">
                Blog
              </span>
              <div className="title-black">
                <h4>{module.heading}</h4>
              </div>
            </div>
            {module.blogDetails.map((item, i) => {
              return (
                <>
                  {item.description && (
                    <div className="content global-list lg:px-60 px-0 ">
                      {parse(item.description)}
                    </div>
                  )}
                  {item.description === null && (
                    <div className="img lg:py-60 py-30">
                      <img
                        src={item.image.mediaItemUrl}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogContent;

export const BlogContentBlogFragment = graphql`
  fragment BlogContentBlogFragment on WpPost_Acfblogcontent_BlogContent_BlogContent {
    # content
    id
    heading
    fieldGroupName
    extraClass
    blogDetails {
      description
      fieldGroupName
      image {
        altText
        mediaItemUrl
        uri
        title
      }
    }
  }
`;
